/* :root {
  --doc-height: 100%;
} */
body,
html,
#root {
  margin: 0;
  height: 100%;
}

body {
  background-color: black;
}

p {
  color: $blue;
}

h1 {
  font-family: "Londrina Solid";
  letter-spacing: 0.1em;
  font-weight: 900;
}

.minh-100 {
  min-height: 100%;
}
.fw-strong {
  font-weight: 800;
}

.btn {
  text-transform: uppercase;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

img,
svg,
button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.app {
  background-image: url("/assets/images/bg_radial.svg"),
    url("/assets/images/bg_planica.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-color: $white;
  text-align: center;
  position: relative;
  height: 100%;
  //min-height: 100vh;
  overflow-x: hidden;
  //height: var(--doc-height);
  @media (min-width: 1025px) {
    width: 375px;
    height: 667px;
  }
}

// Progress

.progress-square {
  .sq {
    display: inline-block;
    padding: 5px;
    margin: 5px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: $blue-light;

    &.incorrect {
      background: #ef7d88;
    }

    &.correct {
      background: #79d98e;
    }
  }
}

// Quizes

.quiz {
  .intro {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    z-index: 1;
  }

  .question-section {
    .description {
      //@debug: drop-shadow(0px 9px 3px #4ba5d5);
    }

    img {
      width: 100%;
    }

    .question-progress {
      position: relative;

      .q-progress {
        display: inline-block;
        padding: 10px 20px;

        @include media-breakpoint-down(sm) {
          padding: 10px;
          font-size: 1rem;
        }
      }
    }
  }

  .btn-answer {
    position: relative;
    transition: none;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      filter: brightness(90%);
    }

    &.btn-step-0 {
      background-color: $red;

      &.wrong {
        filter: brightness(60%);
      }
    }

    &.btn-step-1 {
      background-color: $blue;

      &.wrong {
        filter: brightness(60%);
      }
    }

    &.btn-step-2 {
      background-color: $pink;

      &.wrong {
        filter: brightness(60%);
      }
    }

    &.wrong {
      pointer-events: none;
      //filter: brightness(60%);
      border: 2px solid transparent !important;
      background: linear-gradient(
        180deg,
        #cc515d 0%,
        #ffa9b2 139.2%
      ) !important;

      //box-shadow: 0px 0px 10px 2px rgba(#9E1315, 0.2);

      &:before {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/assets/images/icons/icon-cross.svg") no-repeat 0 0;
        background-size: 100%;
      }
    }

    &.correct {
      pointer-events: none;
      border: 2px solid transparent !important;
      background: linear-gradient(
        180deg,
        #46b15d 0%,
        #a5ffb9 139.2%
      ) !important;

      &:before {
        position: absolute;
        content: "";
        width: 27px;
        height: 20px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/assets/images/icons/icon-check.svg") no-repeat;
        background-size: 100%;
      }
    }

    &.btn-image {
      cursor: pointer;
      width: 100%;
      padding: 0;
      border: 0;
      transform: skewX(0deg);

      img {
        width: 100%;
        border: 5px solid #fff;
      }

      &.correct {
        filter: brightness(100%);
        img {
          filter: brightness(30%);
        }
        &:before {
          background: url("/assets/images/icons/icon-check-c.svg") no-repeat;
          background-size: 100%;
          z-index: 2;
          width: 25%;
          height: 25%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.wrong {
        filter: brightness(100%);
        img {
          filter: brightness(30%);
        }
        &:before {
          background: url("/assets/images/icons/icon-cross-c.svg") no-repeat;
          background-size: 100%;
          z-index: 2;
          width: 25%;
          height: 34%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

// Helpers
.w-35 {
  width: 35%;
}

.button-back {
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
}

.button-info {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.height-100 {
  min-height: 100vh;
  height: var(--doc-height);
}

.button-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  animation: float 3s ease-in-out infinite;
  &:hover {
    animation-play-state: paused;
  }
}

.bg-gradient-blue {
  background: $blue-gradient;
}

.bg-gradient-blue-invert {
  background: $blue-gradient-invert;
}

.bg-gradient-blue-dark {
  background: linear-gradient(180deg, #063565 0%, #1174d8 139.2%);
  p {
    color: $white;
  }
}

.bg-gradient-blue-light {
  background: linear-gradient(180deg, #095db2 0%, #6eb1f4 139.2%);
}

.bg-gradient-yellow {
  background: radial-gradient(50% 50% at 50% 50%, #fff59b 45.62%, #cfba00 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 auto;
  background: radial-gradient(50% 50% at 50% 50%, #94e3fe 0%, #095db2 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.shadow-dark {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__yellow {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #fff59b 45.62%,
      #cfba00 100%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  img {
    height: 30px;
  }
}

// Grid square 4x
.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  div {
    aspect-ratio: 1;
  }
}

// Hints container

.hint {
  position: relative;

  .text {
    padding-left: 50px;
    &:before {
      position: absolute;
      content: "";
      width: 38px;
      height: 38px;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: url("/assets/images/icons/icon-hint.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }
}

// Animated stars header
.headline-star-container {
  overflow: visible;
  margin-bottom: 10px;
  margin-top: 10px;
  .heading-star {
    line-height: 1;
    display: inline-block;
    position: relative;
    font-family: "Londrina Solid";
    letter-spacing: 0.1em;
    font-size: 2rem;

    .star {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      //opacity: 0;
      //transform: scale(0);
    }

    .star-left {
      right: 100%;
    }
    .star-right {
      left: 100%;
    }

    &__big {
      .star {
        height: 58px;
        width: 111px;
      }
    }

    svg {
      //transform-origin: center center;
    }

    path {
      //transform-origin: center center;
    }

    @for $i from 0 through 8 {
      g:nth-child(#{$i}) {
        animation: blink 5s ease-in-out;
        animation-delay: $i / 2 + s;
        animation-iteration-count: 1;
        //animation-fill-mode: forwards;
        transform-origin: center center;
      }
    }
  }
}

// TEMP VR screen
.vr-screen {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
}

// Ali ste vedeli box
.didyouknow {
  background: linear-gradient(220.14deg, #fff59b 22.87%, #ebe07c 130.54%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
// Route based

// Animations
@keyframes float {
  0% {
    transform: translatey(0px) translateX(-50%);
  }
  50% {
    transform: translatey(-10px) translateX(-50%);
  }
  100% {
    transform: translatey(0px) translateX(-50%);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
