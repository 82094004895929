.map {
  svg {
  }
  .active {
    border-color: rgba($blue, 1);
    background: rgba($blue, 1);
  }
  .completed {
    border-color: rgba($green, 0.4);
    background: rgba($green, 0.4);
  }

  .incompleted {
    border-color: rgba($red, 0.4);
    background: rgba($red, 0.4);
  }
}
