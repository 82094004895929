//Password
.password {
  input:checked + span {
  }
  label input {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  label span {
    display: block;
    cursor: pointer;
    //background: linear-gradient($white 0 0) padding-box,
    //linear-gradient(to bottom, $yellow, $yellow) border-box;
    color: $white;
    //border: 2px solid transparent;
    border-radius: 100%;
    border: 4px solid transparent;
    width: 65px;
    height: 65px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .status {
    > div {
      display: inline-block;
      margin: 0 5px;
      width: 88px;
      height: 88px;
      border-radius: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      img {
        width: 35px;
        height: 35px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .keyboard {
    width: 280px;
    margin: 0 auto;
    img {
      width: 25px;
    }
  }
  .item_1 {
    background-color: #095db2;
  }

  .item_2 {
    background-color: #e379d8;
  }

  .item_3 {
    background-color: #f8d41a;
  }

  .item_4 {
    background-color: #e3b279;
  }

  .item_5 {
    background-color: #94e3fe;
  }

  .item_6 {
    background-color: #79d98e;
  }

  .item_undefined {
    background-color: $white;
  }

  &.game2 {
    .item_2 {
      background-color: #ef7d88;
    }
  }
}
