.statusbar {
  background-color: rgba($blue, 1);
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 50px;

  .status-pins {
    .status-pin {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 100%;
      margin: 0 2px;

      img {
        display: none;
      }
    }

    .completed {
      img.found {
        display: block;
        width: 100%;
      }

      &.pin-1 {
        background-color: #ffd7f9;
        border: 3px solid #ffbaf4;
      }

      &.pin-2 {
        background-color: #e5ffea;
        border: 3px solid #baffc9;
      }
      &.pin-3 {
        background-color: #ddf7ff;
        border: 3px solid #94e3fe;
      }
      &.pin-4 {
        background-color: #ffd4d8;
        border: 3px solid #ffb3ba;
      }
      &.pin-5 {
        background-color: #fdfde1;
        border: 3px solid #ffffba;
      }
      &.pin-6 {
        background-color: #ffecd7;
        border: 3px solid #ffdfba;
      }
    }

    .incompleted,
    .active {
      border: 3px solid transparent;
      background-color: rgba($white, 0.4);
      img.notfound {
        display: block;
      }
    }
  }

  .logo {
    margin-left: 15px;
    img {
      width: 60px;
    }
  }

  .hamburger {
    margin-right: 15px;
    cursor: pointer;
  }
}

.offcanvas {
  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(299deg)
      brightness(102%) contrast(102%);
    font-size: 1.1rem;
    opacity: 1;
    font-weight: 900;
  }

  .logo {
    width: 72px;
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .content {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .badges {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;

    div {
      aspect-ratio: 1;
      img {
        width: 100%;
      }

      &.incompleted,
      &.active {
        img {
          filter: blur(0.5rem);
        }
      }
    }
  }
}
