.main-button {
  margin-bottom: 40px;
  font-family: "Londrina Solid", cursive;
  font-weight: 900;
  letter-spacing: 0.1em;
  background: linear-gradient(180deg, #1970c9 0%, #12569a 100%);
  border: 4px solid #94e3fe;
  box-shadow: 0px 0px 5px #94e3fe, 0px 0px 40px #94e3fe,
    0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    border: 4px solid #94e3fe !important;
  }

  &:active {
    transform: scale(1.2);
    transition: all 0.4s linear;
  }

  &.btn-white {
    background: $white;
    border: 4px solid $blue;
    color: $blue;

    &:hover {
      color: $blue;
      border: 4px solid $blue !important;
    }
  }

  &.btn-md {
    padding: 0.5rem 1rem;
  }

  &.btn-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
  }

  &.icon {
    position: relative;
    padding-left: 80px;
    &:before {
      position: absolute;
      content: "";
      height: 34px;
      width: 31px;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      background: url("/assets/images/icons/preberi.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }
}
