@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@900&family=Nunito:wght@500;700;800;900&display=swap");

@import "./variables.scss";
@import "./global.scss";

@import "components/Button/Button.scss";
@import "components/DashBoard/DashBoard.scss";
@import "components/StatusBar/StatusBar.scss";
@import "components/Wrapper/Wrapper.scss";
@import "components/SwipeUnlock/SwipeUnlock.scss";
@import "views/LangPicker/LangPicker.scss";
@import "views/Start/Start.scss";
@import "views/Game/PreGameIntro/PreGameIntro.scss";
@import "views/Loader/Loader.scss";
@import "views/Password/Password.scss";
@import "views/Map/Map.scss";
@import "views/Game/PinOpened/PinOpened.scss";
@import "views/Game/GameBrief/GameBrief.scss";
@import "views/Game/GamePassword/GamePassword.scss";
@import "views/Game/GameBadge/GameBadge.scss";
@import "views/Landing/Landing.scss";
@import "views/Selfie/Selfie.scss";
@import "views/Game/Game.scss";
@import "views/Game/Games/Game1/Game1.scss";
@import "views/Game/Games/Game2/Game2.scss";
@import "views/Game/Games/Game3/Game3.scss";
@import "views/Game/Games/Game4/Game4.scss";
@import "views/Game/Games/Game5/Game5.scss";
