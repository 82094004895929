.game5 {
  .answer-section {
    display: grid;
    grid-auto-columns: 2fr;
    grid-auto-flow: column;
    gap: 10px;
    .answer-option {
      border-radius: 5px;
      aspect-ratio: 1;
      border: 2px solid #095db2;
      background: $white;
      color: $blue;
      font-size: 1.1rem;
    }

    .btn-answer {
      svg {
        width: 40px;
        margin-bottom: 7px;
        pointer-events: none;
      }

      &.wrong,
      &.correct {
        &:before {
          left: 50%;
          top: 15px;
          transform: translateX(-50%);
        }

        color: $white;

        svg {
          filter: brightness(0) invert(1) !important;
        }
      }
    }
  }

  .answer-section {
    margin: 0 50px;
  }

  .counter {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 0;
    left: 0;
  }
}
