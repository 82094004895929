.game-brief {
  .heading {
    font-weight: 800;
    position: relative;
    text-align: center;

    &:before {
      display: block;
      margin: 0 auto;
      //position: absolute;
      margin-bottom: 5px;
      text-align: center;
      content: "";
      width: 38px;
      height: 38px;
      background: url("/assets/images/icons/icon-hint.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }

  ul {
    padding-left: 0;
    li {
      position: relative;
      height: 50px;
      text-align: left;
      padding-left: 50px;
      list-style: none;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      &:before {
        display: block;
        position: absolute;
        left: 0;
        text-align: center;
        content: "";
        width: 38px;
        height: 38px;
        background: url("/assets/images/icons/exclamation.svg") no-repeat 0 0;
        background-size: 100%;
      }
    }
  }
}
