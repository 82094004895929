.game3 {
  .answer-section {
    padding: 0 50px;
    .answer-option {
      border-radius: 5px;
      padding: 15px 0;
      background: $white;
      border: 2px solid #095db2;
      border-radius: 5px;
      color: $blue;
    }

    .btn-answer {
      &.wrong,
      &.correct {
        &:before {
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
