.game4 {
  .question-section {
    display: none;
  }
  .answer-section {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 10px;
    .answer-option {
      border-radius: 5px;
      aspect-ratio: 1;
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      font-size: 1.4rem;
    }

    .btn-answer {
      &.wrong,
      &.correct {
        &:before {
          left: 50%;
          top: 15px;
          transform: translateX(-50%);
        }
      }
    }
  }

  .locked {
    .quiz {
      display: none;
    }
  }
}
