.locked {
  pointer-events: none;
  filter: blur(3px);
}

.pregame {
  .locked {
    filter: none;
    pointer-events: all;

    .btn {
      pointer-events: none;
      opacity: 0.6;
    }
  }
  .unlocked {
    .swipe-unlock {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
