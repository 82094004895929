.game1 {
  .answer-section {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 10px;
    .answer-option {
      border-radius: 5px;
      aspect-ratio: 1;
      background: $white;
      border: 2px solid $blue;
      color: $blue;
      white-space: pre-line;
    }

    .btn-answer {
      &.wrong,
      &.correct {
        &:before {
          left: 50%;
          top: 15px;
          transform: translateX(-50%);
        }
      }

      img {
        height: 30px;
      }
    }
  }
}
