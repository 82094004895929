// SWIPER
$size: 32;

input[type="range"]::-ms-thumb {
  margin: 0; /* Reset margin in Edge since it supports -webkit-slider-thumb as well */
}

@mixin track($color: red, $radius: 0) {
  &::-webkit-slider-runnable-track {
    @content;
    border-radius: $radius;
    background-color: $color;
  }
  &::-moz-range-track {
    @content;
    border-radius: $radius;
    background-color: $color;
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-ms-track {
    border: 0;
    @content;
    border-radius: $radius;
    background-color: $color;
    color: transparent;
  }
  &::-ms-fill-lower,
  &::-ms-fill-upper {
    background-color: transparent;
  }
  &::-ms-tooltip {
    display: none;
  }
}

@mixin thumb() {
  &::-webkit-slider-thumb {
    appearance: none;
    @content;
  }
  &::-moz-range-thumb {
    border: 0;
    @content;
  }
  &::-ms-thumb {
    @content;
  }
}

.swipe-unlock {
  background: linear-gradient(180deg, #063565 0%, #1174d8 139.2%);
  border-radius: 14px;
  height: 100px;
  padding: 20px;

  .pullee {
    width: 100%;
    appearance: none;
    background: transparent;
    //display: flex;
    //align-items: center;

    // handle active states
    &:active {
      @include thumb() {
        transform: scale(1.1);
        -webkit-box-shadow: 0px 0px 15px 4px $blue-light;
        -moz-box-shadow: 0px 0px 15px 4px $blue-light;
        box-shadow: 0px 0px 15px 4px $blue-light;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
      }
    }

    // accessibility
    &:focus {
      outline: none;
    }

    // thumb styles
    @include thumb() {
      display: block;
      width: 60px;

      height: 60px;
      border-radius: 50%;
      background-image: url("/assets/images/icons/swipe-thumb.svg");
      background-position: center;
      background-size: 60px;
      transform-origin: 50% 50%;
      transform: scale(1);
      transition: transform ease-out 100ms;
      margin-top: -20px;
      -webkit-box-shadow: 0px 0px 12px 4px $blue-light;
      -moz-box-shadow: 0px 0px 12px 4px $blue-light;
      box-shadow: 0px 0px 12px 4px $blue-light;
      cursor: -webkit-grab;
      cursor: -moz-grab;
    }

    // track styles
    @include track(red, 40px) {
      height: 20px;
      width: 100%;
      //padding: 0.25rem;
      box-sizing: content-box;

      background: linear-gradient(90deg, #f9e831 -15.55%, #94e3fe 120.69%);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.38);
      //border-radius: 5px;
    }
  }

  &.active {
    svg.star {
      transform-origin: center;
      path {
        transform-origin: center;
      }

      @for $i from 0 through 8 {
        g:nth-child(#{$i}) {
          animation: blink 5s ease-in-out infinite;
          animation-delay: $i / 2 + s;
        }
      }
    }
  }

  .action-button {
    width: 100px;
    height: 100px;
    background-color: white;
  }
}
