.wrapper {
  position: relative;
  padding: 50px 20px 20px 20px;
  //height: 100%;

  &.ar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
