// Colors
$blue: #095db2;
$blue-light: #94e3fe;
$turquise: #94e3fe;
$white: #fff;
$black: #000;
$red: rgb(187, 34, 34);
$yellow: #dfdf6d;
$blue-gradient: linear-gradient(180deg, $blue 0%, $blue-light 139.2%);
$blue-gradient-invert: linear-gradient(0deg, $blue 0%, $blue-light 139.2%);

// Fonts
$font-family-sans-serif: "Nunito", sans-serif;
$primary: $blue;
$secondary: $white;

$headings-font-family: "Nunito", sans-serif;
$headings-color: $blue;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
);

$theme-colors: (
  "primary": $blue,
  "secondary": $white,
  "blue": $blue,
  "blue-light": $blue-light,
  "turquise": $turquise,
  "white": $white,
  "danger": $red,
  "yellow": $yellow,
);

$display-font-sizes: (
  1: 3rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 1.6rem,
  8: 1.3rem,
);

$font-size-base: 0.95rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$lead-font-size: 1.3rem;
$lead-font-weight: 800;

$h1-font-size: 2rem;

$line-height-base: 1.25;
$line-height-sm: 1;
$line-height-lg: 1.5;

//$headings-font-weight: 700;

$btn-focus-box-shadow: 0;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1.5rem;
$btn-padding-x: 3rem;
$btn-padding-y: 0.8rem;
$btn-border-width: 3px;
$btn-font-size-sm: 0.8rem;
$btn-border-radius-sm: 1rem;
$btn-font-weight: 700;

$border-radius: 1.875rem;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $black;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-border-color: $white;
$input-border-width: 1px;
$input-focus-border-color: $white;
$input-color: $black;
$input-focus-box-shadow: none;
$input-padding-x: 1.5rem;
$input-padding-y: 1rem;
$form-check-input-width: 1.5em;
$form-check-input-border: 3px solid $white;
$form-check-input-border-radius: 50%;

$enable-negative-margins: true;
$enable-rfs: false;

$offcanvas-horizontal-width: 100%;

@import "~bootstrap/scss/bootstrap";
