.pregame {
  ul {
    padding-left: 0;
    li {
      position: relative;
      height: 50px;
      text-align: left;
      padding-left: 50px;
      list-style: none;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &:before {
        display: block;
        position: absolute;
        left: 0;
        text-align: center;
        content: "";
        width: 38px;
        height: 38px;
      }

      &:nth-child(1) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon1.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
      &:nth-child(2) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon2.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
      &:nth-child(3) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon3.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
      &:nth-child(4) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon4.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
      &:nth-child(5) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon5.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
      &:nth-child(6) {
        &:before {
          background: url("/assets/images/icons/gamestart/icon6.svg") no-repeat
            0 0;
          background-size: 100%;
        }
      }
    }
  }

  .swipe-unlock {
    box-shadow: 0px 0px 34px 4px #ffffba;
  }
}
