.game-badge,
.badges {
  img.game1 {
    filter: drop-shadow(2px 1px 30px #e379d8) drop-shadow(2px 1px 40px #e379d8);
  }
  img.game2 {
    filter: drop-shadow(2px 1px 30px #ef7d88) drop-shadow(2px 1px 40px #ef7d88);
  }
  img.game3 {
    filter: drop-shadow(2px 1px 30px #55bcdf) drop-shadow(2px 1px 40px #55bcdf);
  }
  img.game4 {
    filter: drop-shadow(2px 1px 30px #79d98e) drop-shadow(2px 1px 40px #79d98e);
  }
  img.game5 {
    filter: drop-shadow(2px 1px 30px #dfdf6d) drop-shadow(2px 1px 40px #dfdf6d);
  }
  img.game6 {
    filter: drop-shadow(2px 1px 30px #e3b279) drop-shadow(2px 1px 40px #e3b279);
  }
}
